const bustRemoteEntriesCache = () => {
  const { REACT_APP_ENGYN_FORMS_MICRO, REACT_APP_ENGYN_CORE_MICRO } =
    window.env || {}

  if (!REACT_APP_ENGYN_FORMS_MICRO || !REACT_APP_ENGYN_CORE_MICRO)
    return new Promise(resolve => resolve())

  const remoteUrls = [REACT_APP_ENGYN_CORE_MICRO, REACT_APP_ENGYN_FORMS_MICRO]

  return Promise.all(
    remoteUrls.map(remoteUrl =>
      fetch(`${remoteUrl}/remoteEntry.js`, { cache: 'reload', mode: 'no-cors' })
    )
  )
}

bustRemoteEntriesCache()?.finally(() => import('./bootstrap'))
